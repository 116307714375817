var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit_sys_account"},[_c('Layout',{attrs:{"showLoading":_vm.loading},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{class:[
          'content',
          _vm.schoolInfoArr && _vm.schoolInfoArr.length > 0 ? 'long_w' : 'short_w' ]},[_c('h3',[_vm._v("编辑系统用户账号")]),_c('el-card',{staticClass:"box margin_top_30"},[_c('div',{staticClass:"top"},[_c('div',[_c('span',[_vm._v("账号类型："+_vm._s(_vm.adminInfo.role))]),_c('span',{staticClass:"margin_left_20"},[_vm._v("登录手机号："+_vm._s(_vm.adminInfo.tel))])]),_c('div',{staticClass:"com_dv margin_top_30"},[_c('span',[_vm._v("用户名：")]),_c('el-input',{attrs:{"clearable":"","placeholder":"输入用户名"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1),(_vm.schoolInfoArr && _vm.schoolInfoArr.length > 0)?_c('div',{staticClass:"school_info margin_top_30"},[_c('span',[_vm._v("关联校区")]),_c('div',{staticClass:"gkxq"},_vm._l((_vm.schoolInfoArr),function(item,index){return _c('div',{key:index,staticClass:"item_school"},[_c('span',{class:[
                      'common_box',
                      'iconfont',
                      item.isSelected
                        ? 'icon-checkbox'
                        : 'icon-CheckboxUnchecked' ],on:{"click":function($event){$event.stopPropagation();return _vm.doSelectAction(item)}}}),_c('span',{staticClass:"margin_left_4"},[_vm._v(_vm._s(item.id))]),_c('span',{staticClass:"margin_left_8 school_name",attrs:{"title":item.name}},[_vm._v("("+_vm._s(item.name)+")")])])}),0)]):_vm._e()]),_c('div',{staticClass:"bom"},[_c('el-button',{class:['commit_bt', 'bt_active'],attrs:{"loading":_vm.showLoading},on:{"click":function($event){$event.stopPropagation();return _vm.doSaveAction.apply(null, arguments)}}},[_vm._v("保    存")]),_c('el-button',{class:['commit_bt', 'bt_normal'],on:{"click":function($event){$event.stopPropagation();return _vm.doCancelAction.apply(null, arguments)}}},[_vm._v("取    消")])],1)])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }