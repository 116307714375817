<!-- 编辑系统账户 -->
<template>
  <div class="edit_sys_account">
    <Layout :showLoading="loading">
      <template v-slot:content>
        <!-- 视图部分 -->
        <div
          :class="[
            'content',
            schoolInfoArr && schoolInfoArr.length > 0 ? 'long_w' : 'short_w',
          ]"
        >
          <!-- 标题 -->
          <h3>编辑系统用户账号</h3>

          <el-card class="box margin_top_30">
            <div class="top">
              <!-- 账户类型 -->
              <div>
                <span>账号类型：{{ adminInfo.role }}</span
                ><span class="margin_left_20"
                  >登录手机号：{{ adminInfo.tel }}</span
                >
              </div>

              <!-- 用户名 -->
              <div class="com_dv margin_top_30">
                <span>用户名：</span>
                <el-input
                  clearable
                  v-model="userName"
                  placeholder="输入用户名"
                ></el-input>
              </div>

              <!-- 关联校区 -->
              <div
                class="school_info margin_top_30"
                v-if="schoolInfoArr && schoolInfoArr.length > 0"
              >
                <span>关联校区</span>
                <!-- 关联校区的显示区域 -->
                <div class="gkxq">
                  <div
                    class="item_school"
                    v-for="(item, index) in schoolInfoArr"
                    :key="index"
                  >
                    <!-- 超级管理员不能进行操作 -->
                    <span
                      @click.stop="doSelectAction(item)"
                      :class="[
                        'common_box',
                        'iconfont',
                        item.isSelected
                          ? 'icon-checkbox'
                          : 'icon-CheckboxUnchecked',
                      ]"
                    ></span>
                    <span class="margin_left_4">{{ item.id }}</span>
                    <span :title="item.name" class="margin_left_8 school_name"
                      >({{ item.name }})</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- 底部的保存和取消 -->
            <div class="bom">
              <el-button
                :loading="showLoading"
                :class="['commit_bt', 'bt_active']"
                @click.stop="doSaveAction"
                >保&nbsp;&nbsp;&nbsp;&nbsp;存</el-button
              >
              <el-button
                :class="['commit_bt', 'bt_normal']"
                @click.stop="doCancelAction"
                >取&nbsp;&nbsp;&nbsp;&nbsp;消</el-button
              >
            </div>
          </el-card>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //加载框
      loading: -1,

      //保存时的loading
      showLoading: false,

      //用户名
      userName: "",

      //请求到的用户信息
      adminInfo: null,

      //管理员id
      id: "",

      //关联校区的数据
      schoolInfoArr: [],
    };
  },
  created() {
    this.httpData();
  },
  methods: {
    //获取数据
    httpData() {
      this.id = this.$route.query.id;
      //请求服务器做禁用和启用的请求
      let param = this.getHttpParams();
      param.type = 3;
      param.id = this.id;
      this.$http
        .fetchPost(this.$api.ADMINMANAGE, param)
        .then((res) => {
          let result = res.data;
          this.loading = 1;
          if (result.state == 200) {
            //获取用户细腻
            this.adminInfo = result.data.adminInfo;
            this.userName = this.adminInfo.name;
            let relationList = result.data.relationList;
            if (!this.isEmpty(relationList)) {
              relationList.forEach((item) => {
                //给每个条目配一个选中状态
                if (item.state == 1) {
                  this.$set(item, "isSelected", true);
                } else {
                  this.$set(item, "isSelected", false);
                }
              });
              this.schoolInfoArr = relationList;
            }
          }
        })
        .catch((err) => {
          this.loading = 1;
          console.log("请求数据异常");
        });
    },

    //选中和取消的操作
    doSelectAction(item) {
      //超级管理员给出不能进行操作的提示
      if (this.adminInfo.role == "超级管理员") {
        this.showWarnMsg("不能对超级管理员进行关联校区的操作");
      } else {
        item.isSelected = !item.isSelected;
      }
    },

    //保存的操作
    doSaveAction() {
      if (this.isEmpty(this.userName)) {
        this.showWarnMsg("请输入用户名");
        return;
      }

      //判断修改的用户名是否包含数字和特殊字符
      // if (this.regExp(this.userName)) {
      //   this.showWarnMsg("输入的用户名不能包含数字和特殊字符");
      //   return;
      // }

      //判断是否做了修改
      if (this.hasChange()) {
        //修改了用户信息,上传用户修改信息
        this.showLoading = true;

        let param = this.getHttpParams();
        param.type = 4;
        param.id = this.adminInfo.id;

        if (this.userName != this.adminInfo.name) {
          //表示修改了姓名
          param.name = this.userName;
        }

        if (
          !this.isEmpty(this.schoolInfoArr) &&
          this.schoolInfoArr.length > 0
        ) {
          if (this.hasUnSelected()) {
            //表示有取消关联校区操作
            param.sid = this.getSchoolIds();
          }
        }

        this.$http
          .fetchPost(this.$api.ADMINMANAGE, param)
          .then((res) => {
            let result = res.data;
            this.showLoading = false;
            if (result.state == 200) {
              //请求成功
              this.showSuccessMsg("修改成功");
              this.$router.go(-1);
            } else {
              this.showErrorMsg(`${result.msg},修改失败`);
            }
          })
          .catch((err) => {
            this.showLoading = false;
            console.log("请求数据异常");
          });
      } else {
        this.showWarnMsg("未修改用户信息,无需进行保存操作");
      }
    },

    //判断是否修改了用户信息
    hasChange() {
      if (this.userName != this.adminInfo.name) {
        //表示修改了信息,需要保存
        return true;
      }

      //有关联学校时需要判定是否有取消关联学校,如果有取消则证明修改了用户信息
      if (!this.isEmpty(this.schoolInfoArr) && this.schoolInfoArr.length > 0) {
        return this.hasUnSelected();
      }

      return false;
    },

    //判断是否有取消,有取消则证明修改了信息
    hasUnSelected() {
      let res = false;
      for (let i = 0; i < this.schoolInfoArr.length; i++) {
        if (!this.schoolInfoArr[i].isSelected) {
          res = true;
          break;
        }
      }
      return res;
    },

    //获取取消的关联校区的id,用-拼接
    getSchoolIds() {
      //将所有没有选中的条目map出来
      let unSelectedArr = this.schoolInfoArr.filter((item) => !item.isSelected);

      //取出id
      let idsArr = unSelectedArr.map((item) => {
        return item.id;
      });

      //拼接id
      return idsArr.join("-");
    },

    //取消的操作
    doCancelAction() {
      //返回
      this.$router.go(-1);
    },
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.edit_sys_account {
  height: calc(100vh - 307px);
  display: flex;
  min-width: $min_width;
  .content {
    margin-top: 40px;
    margin: 0 30px;
    .box {
      padding: 20px 10px 10px;
      .com_dv {
        display: flex;
        align-items: center;
        ::v-deep .el-input__inner {
          height: 30px;
          line-height: 30px;
          padding-left: 6px;
        }

        ::v-deep .el-input__icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        ::v-deep .el-input {
          width: 210px;
        }

        ::v-deep .el-input__suffix {
          right: 0px;
        }
      }

      .school_info {
        display: flex;
        //关联校区
        .gkxq {
          flex: 1;
          margin-left: 20px;
          min-height: 250px;
          max-height: 250px;
          overflow-y: auto;
          // display: flex;

          .item_school {
            float: left;
            margin-bottom: 20px;
            width: 33.33%;
            display: flex;
            align-content: center;

            .notclickn {
              pointer-events: none;
              // cursor: not-allowed;
            }
            .common_box {
              cursor: pointer;
              font-size: 18px;
              color: #409eff;
            }
            .school_name {
              font-size: 12px;
              max-width: 126px;
              white-space: nowrap;
              word-wrap: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .bom {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .bt_normal {
          background: #cfd1d4;
          color: white;
          margin-left: 80px;
        }

        .bt_active {
          background: #409eff;
          color: white;
          cursor: pointer;
        }

        .commit_bt {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 34px;
          width: 100px;
          border: none;
        }
      }
    }
  }

  .long_w {
    width: 70%;
  }

  .short_w {
    width: 30%;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
  }

  //定义滚动条轨道  内阴影+圆角
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  //定义滑块内阴影+圆角
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.01);
    background-color: #409eff;
  }
}
</style>
